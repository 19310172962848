/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
import Swiper, { Navigation } from 'swiper';
import gsap from 'gsap';
import SplitText from '@lib/gsap-pro/SplitText';
import ScrollTo from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';

Swiper.use([Navigation]);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollTo);

/*
|
| Importing Components
|-----------------------
*/
import customGoogleMap from '@components/custom-google-map';
import MaterializeForm from '@components/materialize-form';
import Kira from '@components/kira';
import Menu from '@components/menu';
import Overlay from '@components/overlays';
import MobileDetect from '@components/mobile-detect'

/*
|
| Importing Utils
|-------------------
*/
import * as app from '@utils/global';
import Router from '@utils/router';
import Ajax from '@utils/ajax';
import Plyr from '@components/plyr.min.js';

/*
|
| Importing App files
|----------------------
*/
import main from '@pages/main';
import news from '@pages/news';
import animations from '@pages/animations';
import single from '@pages/single';
import contact from '@pages/contact';
import sector from '@pages/sector';
import about from '@pages/about';

/*
|
| Importing Blocks files
|----------------------
*/
import work from './blocks/work';

window.MD = new MobileDetect()

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        file: animations,
        dependencies: [app, gsap, ScrollTrigger, SplitText, Menu, Kira]
    },
    {
        file: main,
        dependencies: [app, gsap, Overlay, Swiper],
    },
    {
        file: news,
        dependencies: [app, Ajax],
        resolve: '#page-news-archive'
    },
    {
        file: single,
        dependencies: [app, Plyr],
        resolve: '.single'
    },
    {
        file: contact,
        dependencies: [app, MaterializeForm, customGoogleMap],
        resolve: '#page-contact'
    },
    {
        file: work,
        dependencies: [app, gsap, ScrollTrigger],
        resolve: '.work'
    },
    {
        file: sector,
        dependencies: [app, Swiper, gsap],
        resolve: '#page-sector'
    },
    {
        file: about,
        dependencies: [app],
        resolve: '#page-about'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

