/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, gsap, Overlay, Swiper) => {

		/*
		|
		| Menu
		|-----------------
		*/
		jQuery('.item-nav .menu-item-has-children').click(function () {


			if ($(this).hasClass('active')) {
				$("body").find(".overlay").removeClass('over-active');
				$("body").removeClass('menu-open');

				$(this).siblings(".menu-item-has-children").removeClass('active');
				$(this).siblings(".menu-item-has-children").find(".sm-menu").slideUp(200);

				$(this).toggleClass('active');
				$(this).find(".sm-menu").slideToggle(400);
			} else {
				$("body").find(".overlay").addClass('over-active');
				$("body").addClass('menu-open');

				$(this).siblings(".menu-item-has-children").removeClass('active');
				$(this).siblings(".menu-item-has-children").find(".sm-menu").slideUp(200);

				$(this).toggleClass('active');
				$(this).find(".sm-menu").slideToggle(400);

			}
		});

		jQuery('.overlay').click(function () {

			$("body").find(".menu-item-has-children.active").find(".sm-menu").slideUp(400);

			$("body").find(".menu-item-has-children.active").removeClass('active');

			$("body").find(".overlay").removeClass('over-active');
			$("body").removeClass('menu-open');
		});

		/*
		|
		| Scroll menu fixed
		|-----------------
		*/
		var prevScrollpos = window.pageYOffset;
		window.onscroll = function () {
			var currentScrollpos = window.pageYOffset;
			if (prevScrollpos > currentScrollpos) {
				document.getElementById("header").style.top = "0";
				$(".sm-menu").slideUp();
			} else {
				document.getElementById("header").style.top = "-90px";
			}
			if (currentScrollpos > 50) {
				jQuery("header").removeClass('top');
				$(".sm-menu").slideUp();
			} else {
				jQuery("header").addClass('top');
			}

			prevScrollpos = currentScrollpos;

			if (currentScrollpos < 30) {
				document.getElementById("header").style.top = "0";
				$(".sm-menu").slideUp();
			}
		}

		/*
		|
		| Menu mobile
		|-----------------
		*/
		$(document).ready(function () {
			$('#mobile-menu .chevron').on('click', function () {
				var parent = $(this).closest('.menu-item-has-children');
				// Fermer tous les autres sous-menus ouverts
				$('#mobile-menu .menu-item-has-children').not(parent).removeClass('active').find('.nav-drop').slideUp();
				$('#mobile-menu .menu-item-has-children').not(parent).find('.chevron').removeClass('rotate');

				// Gérer le sous-menu du clic en cours
				parent.toggleClass('active');
				parent.find('.nav-drop').slideToggle();
				$(this).toggleClass('rotate');

				// Ajouter de l'opacité à tous les autres éléments de menu
				if (parent.hasClass('active')) {
					$('#mobile-menu .menu-item').not(parent).css('opacity', '0.5');
				} else {
					$('#mobile-menu .menu-item').not(parent).css('opacity', '1');
				}
			});
		});


		/*
		|
		| Anchor scroll
		|-----------
		*/
		if ($(".about_anchors").length) {
			let scrollTop = $(window).scrollTop();
			let elementOffset = $('.about_anchors').offset().top;
			let distance = (elementOffset - scrollTop);

			jQuery(document).ready(function () {
				if ($(".about_anchors")[0]) {
					$(window).scroll(function () {
						var height = $(window).scrollTop();
						if (height >= distance) {
							if (!$(".notify").length) {
								jQuery("body").find(".anchor-fixed").css({ top: '0px' });
							}
							$(".about_anchors").addClass("anchor-fixed");
						} else {
							if (!$(".notify").length) {
								jQuery("body").find(".anchor-fixed").css({ top: '0' });
							}
							$(".about_anchors").removeClass("anchor-fixed");
						}
					});
				}
			});
		}




		/*
		|
		| Anchors
		|-----------------
		*/
		var sections = $('.block-anchor');
		var nav = $('.about_anchors');
		var nav_height = nav.outerHeight();
		var offsettop = 50;

		$(window).on('scroll', function () {
			var cur_pos = $(this).scrollTop();

			sections.each(function () {
				var top = $(this).offset().top - nav_height - offsettop,
					bottom = top + $(this).outerHeight();

				if (cur_pos >= top - 5) {
					nav.find('a').removeClass('active');
					sections.removeClass('active');

					$(this).addClass('active');
					nav.find('a[href="#' + $(this).attr('id') + '"]').addClass('active');
				}
			});
		});

		nav.find('a').on('click', function () {
			var $el = $(this)
				, id = $el.attr('href');

			$('html, body').animate({
				scrollTop: $(id).offset().top - nav_height - offsettop
			}, 500);

			return false;
		});


		/*
		|
		| Scroll
		|-----------------
		*/
		$('.scroll').on('click', function (e) {
			var href = $(this).attr('href');
			$('html, body').animate({
				scrollTop: $(href).offset().top
			}, '1800');
			e.preventDefault();
		});

		/*
		|
		| Video link modal
		|-----------------
		*/
		if ($("#video-header").length) {
			jQuery('.btn-header').click(function (e) {
				jQuery("body").addClass('mod');
				$('#video-header').find('video').attr('src', $(this).data("url"));
				$('#video-header').find('video')[0].load();
				$('.modal').fadeTo("slow", 1);
			});
			jQuery('.close').click(function (e) {
				jQuery("body").removeClass('mod');
				var media = $('#video-header').find('video').get(0);
				media.pause();
				media.currentTime = 0;
				$('.modal').fadeTo("slow", 0);

			});
		}


		/*
		|
		| Video link testimonial
		|-----------------
		*/
		if ($(".btn-testimonial").length) {
			jQuery('.btn-testimonial').click(function (e) {
				jQuery("body").addClass('mod');
				jQuery("body").find('iframe').show();
				jQuery($(this).data("id")).fadeTo("slow", 1);
			});
			jQuery('.close-testimonial').click(function (e) {
				jQuery("body").removeClass('mod');
				jQuery("body").find('iframe').hide();

				/* Stop video */
				$('iframe').each(function (index) {
					$(this).attr('src', $(this).attr('src'));
					return false;
				});

				jQuery($(this).data("id")).fadeTo("slow", 0);
			});
		}

		/*
		|
		| Animation Activités
		|-----------------
		*/
		const videos = document.querySelectorAll(".anim-sector")
		videos.forEach(video => {
			video.addEventListener("mouseover", function () {
				this.play()
			})

			video.addEventListener("mouseout", function () {
				this.pause()
			})

			video.addEventListener("touchstart", function () {
				this.play()
			})

			video.addEventListener("touchend", function () {
				this.pause()
			})
		})

		/*
		|
		| Swiper similars posts
		|-----------------
		var swiper = new Swiper('#swiper-similars-post.swiper-container', {
			slidesPerView: 2.3,
			effect: 'coverflow',
			speed: 1000,
			grabCursor: true,
			navigation: {
				prevEl: '.swiper-btn-prev',
				nextEl: '.swiper-btn-next'
			},
			breakpoints: {
				0: {
					slidesPerView: 1.2,
					spaceBetween: 15
				},
				992: {
					slidesPerView: 1.2,
					spaceBetween: 15,
					centeredSlides: true
				},
				1200: {
					slidesPerView: 2.3,
					spaceBetween: 15
				}
			}
		});
		*/

		/*-------- Lazy loading --------*/
		document.querySelectorAll('[data-src], [data-bg]').forEach((item) => app.lazyLoader.observe(item))

		/*-------- Modals --------*/
		document.querySelectorAll('.modal').forEach((item) => {
			app.overlays[item.id] = new Overlay(item)
		})


	}
}
