/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        let poles = document.querySelectorAll('.poles-item')

        poles.forEach(function(pole) {
            let slug = pole.dataset.pole

            pole.addEventListener('click', function() {
                poles.forEach(function(pl) {
                    pl.classList.toggle('--active', false)
                    pl.classList.toggle('--fade', true)
                })

                pole.classList.toggle('--active', true)
                pole.classList.toggle('--fade', false)

                document.querySelectorAll('.member').forEach(function(pl) {
                    pl.classList.toggle('--hide', !pl.classList.contains('member--'+ slug))
                })
            })
        })

    }
}
